
import {
  defineComponent,
  toRefs,
  reactive,
  ref,
} from 'vue'
import VueViewer, { Viewer, component } from 'v-viewer'
import Flicking from "@egjs/vue3-flicking";
import { AutoPlay, Fade } from "@egjs/flicking-plugins";

const plugins = [
  new Fade()
]

VueViewer.setDefaults({
  zIndexInline: 2021,
})
class ImageData {
  thumbnail: string
  source: string
  title: string
  constructor(source: string, thumbnail: string, title: string) {
    this.source = source
    this.thumbnail = thumbnail
    this.title = title
  }
}
const sourceImages: ImageData[] = []
const base = Math.floor(Math.random() * 60) + 10
const rawImages = [
        ["/images/thaChamberPhotos/HochzeitsRinge4k.png","/images/thaChamberPhotos/HochzeitsRinge400.png","image 1"],
        ["/images/thaChamberPhotos/DarkImageChago4k.png","/images/thaChamberPhotos/DarkImageChago400.png","image 2"],
        ["/images/thaChamberPhotos/BannerFull4k.png","/images/thaChamberPhotos/BannerFull400.png","image 3"],
        ["/images/thaChamberPhotos/entry4k.png","/images/thaChamberPhotos/entry400.png","image 4"],
        ["/images/thaChamberPhotos/Papagei4k.png","/images/thaChamberPhotos/Papagei400.png","image 5"],
        ["/images/thaChamberPhotos/Brueele4k.png","/images/thaChamberPhotos/Brueele400.png","image 6"],
        ["/images/thaChamberPhotos/Tattoo4k.png","/images/thaChamberPhotos/Tattoo400.png","image 7"],
        ["/images/thaChamberPhotos/elephant4k.png","/images/thaChamberPhotos/elephant400.png","image 8"],
        ["/images/thaChamberPhotos/couch4k.png","/images/thaChamberPhotos/couch400.png","image 9"],
        ["/images/thaChamberPhotos/CorsWu4k.png","/images/thaChamberPhotos/CorsWu400.png","image 10"],
        ["/images/thaChamberPhotos/Chamber4k.png","/images/thaChamberPhotos/Chamber400.png","image 11"],
        ["/images/thaChamberPhotos/ThaChamber4k.png","/images/thaChamberPhotos/ThaChamber400.png","image 12"],
      ]
for (const image of rawImages) {
  const data = new ImageData(image[0], image[1], image[1])
  sourceImages.push(data)
}
export default defineComponent({
  name: 'ComponentExample',
  components: {
    viewer: component,
    Flicking,
  },
  setup() {


    let $viewer: Viewer
    const flicking = ref()
    const state = reactive({
      form: {
        view: 2,
        zoom: -0.1,
        zoomTo: 0.8,
        rotate: 90,
        rotateTo: 180,
        scaleX: 1,
        scaleY: 1,
      },
      toggleOptions: [
        'button',
        'navbar',
        'title',
        'toolbar',
        'tooltip',
        'movable',
        'zoomable',
        'rotatable',
        'scalable',
        'transition',
        'fullscreen',
        'keyboard',
      ],
      options: {
        inline: false,
        button: true,
        navbar: true,
        title: true,
        toolbar: true,
        tooltip: true,
        movable: true,
        zoomable: true,
        rotatable: true,
        scalable: true,
        transition: true,
        fullscreen: true,
        keyboard: true,
        url: 'data-source',
      },
      images: sourceImages,
    })
    function inited(viewer: Viewer) {
      $viewer = viewer
    }
    function view() {
      if (state.form.view >= 0 && state.form.view < state.images.length)
        $viewer.view(state.form.view)
    }
    function zoom(value: number) {
      $viewer.zoom(value || state.form.zoom)
    }
    function zoomTo() {
      $viewer.zoomTo(state.form.zoomTo)
    }
    function rotate(value: number) {
      $viewer.rotate(value || state.form.rotate)
    }
    function rotateTo() {
      $viewer.rotateTo(state.form.rotateTo)
    }
    function scaleX(value: number) {
      if (value) {
        $viewer.scaleX(value)
      }
      else {
        state.form.scaleX = -state.form.scaleX
        $viewer.scaleX(state.form.scaleX)
      }
    }
    function scaleY(value: number) {
      if (value) {
        $viewer.scaleY(value)
      }
      else {
        state.form.scaleY = -state.form.scaleY
        $viewer.scaleY(state.form.scaleY)
      }
    }
    function move(x: number, y: number) {
      $viewer.move(x, y)
    }
    function prev() {
      $viewer.prev()
    }
    function next() {
      $viewer.next()
    }
    function play() {
      $viewer.play()
    }
    function stop() {
      $viewer.stop()
    }
    function show() {
      $viewer.show()
    }
    function full() {
      $viewer.full()
    }
    function tooltip() {
      $viewer.tooltip()
    }
    function reset() {
      $viewer.reset()
    }
    function toggleInline(inline: boolean) {
      state.options.inline = inline
    }
    return {
      ...toRefs(state),
      inited,
      view,
      zoom,
      zoomTo,
      rotate,
      rotateTo,
      scaleX,
      scaleY,
      move,
      prev,
      next,
      play,
      stop,
      show,
      full,
      tooltip,
      reset,
      toggleInline,
      flicking,
      plugins
    }
  },
})
