<template>
  <router-view/>
</template>

<style>
body{
  background: #222;
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #EEE;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #EEE;
}

#nav a.router-link-exact-active {
  color: #BBB;
}
</style>
